import Vue from 'vue'
import {
  LMap,
  LTileLayer,
  LImageOverlay,
  LMarker,
  LControl,
  LPopup,
  LCircleMarker,
  LRectangle,
  LPolygon,
  LControlLayers,
  LControlScale
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-image-overlay', LImageOverlay)
Vue.component('l-marker', LMarker)
Vue.component('l-control', LControl)
Vue.component('l-popup', LPopup)
Vue.component('l-circle-marker', LCircleMarker)
Vue.component('l-rectangle', LRectangle)
Vue.component('l-polygon', LPolygon)
Vue.component('l-control-layers', LControlLayers)
Vue.component('l-control-scale', LControlScale)
