import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../src/graphql/queries'
import * as gqlMutations from '../graphql/mutations'

export const state = () => ({
  teamsSearchResult: [],
  myTeams: [],
  teamDetails: {} // {'teamName1': [team, team, team], 'teamName2': [team, team, team], ...}
})

const teamStateOrder = {
  A_ACTIVE: 0,
  D_PASSIVE: 1,
  J_PENDING_NEW: 3,
  H_PENDING_RETURN: 2,
  M_FORCED_OUT: 5,
  P_ABANDONED: 4,
  T_REJECTED_NEW: 6
}

export const mutations = {
  setTeamsSearchResult(state, teams) {
    state.teamsSearchResult = teams.sort(
      (t1, t2) => teamStateOrder[t1.state] > teamStateOrder[t2.state]
    )
  },
  setMyTeams(state, teams) {
    state.myTeams = teams.sort(
      (t1, t2) => t1.lastActiveForTeam < t2.lastActiveForTeam
    )
  },
  appendToTeamDetails(state, teams) {
    if (teams && teams.length > 0) {
      const teamName = teams[0].teamName
      const newDetails = {}
      newDetails[teamName] = teams.sort(
        (t1, t2) => teamStateOrder[t1.state] > teamStateOrder[t2.state]
      )
      state.teamDetails = Object.assign({}, state.teamDetails, newDetails)
    }
  }
}

function getUpdatedTeamDetails(updatedTeamItems, state, isDelete) {
  const updatedTeamDetailsArrays = []
  updatedTeamItems.forEach((updatedTeamItem) => {
    const existingTeamDetails = state.teamDetails[updatedTeamItem.teamName]
    // there might not be any team details, they haven't been fetched yet. Ignore if details haven't been fetched
    if (existingTeamDetails) {
      const existingTeamDetails2 = existingTeamDetails.reduce(
        (accumulator, existingTeamItem) => {
          if (
            existingTeamItem.teamName === updatedTeamItem.teamName &&
            existingTeamItem.userNickName === updatedTeamItem.userNickName
          ) {
            if (!isDelete) {
              accumulator.push(updatedTeamItem)
            }
            return accumulator
          } else {
            accumulator.push(existingTeamItem)
            return accumulator
          }
        },
        []
      )
      updatedTeamDetailsArrays.push(existingTeamDetails2)
    }
  })
  return updatedTeamDetailsArrays
}

function getUpdatedMyTeams(updatedTeamItems, state, isDelete) {
  const updatedTeamsByTeamNameUserNickName = updatedTeamItems.reduce(
    (accumulator, team) => {
      const key = team.teamName + '#' + team.userNickName
      accumulator[key] = team
      return accumulator
    },
    {}
  )
  const updateTeamsKeys = Object.keys(updatedTeamsByTeamNameUserNickName)
  const updatedMyTeams = state.myTeams.reduce((accumulator, team) => {
    const key = team.teamName + '#' + team.userNickName
    if (updateTeamsKeys.includes(key)) {
      if (!isDelete) {
        accumulator.push(updatedTeamsByTeamNameUserNickName[key])
      }
    } else {
      accumulator.push(team)
    }
    return accumulator
  }, [])
  return updatedMyTeams
}

export const actions = {
  async searchTeamNames({ commit }, filter) {
    const variables = {
      teamNameQuery: filter.teamNameQuery.toLowerCase(),
      teamState: 'ACTIVE',
      limit: 100,
      nextToken: ''
    }

    console.debug('Querying team by name with these options: ', variables)
    const response = await API.graphql(
      graphqlOperation(queries.searchTeamNames, variables)
    )
    const teamResults = response.data.searchTeamNames.items
    console.debug('Query result: ', teamResults)
    return commit('setTeamsSearchResult', teamResults)
  },
  async getTeamDetails({ commit }, filter) {
    const variables = {
      teamName: filter.teamName,
      teamState: filter.teamState
    }

    console.debug('Querying team by name with these options: ', variables)
    const response = await API.graphql(
      graphqlOperation(queries.getTeamDetails, variables)
    )
    const teamResults = response.data.getTeamDetails.items
    console.debug('Query result: ', teamResults)
    return commit('appendToTeamDetails', teamResults)
  },
  async setTeamMemberState({ commit, state }, teamMemberState) {
    const variables = {
      teamName: teamMemberState.teamName,
      userNickName: teamMemberState.userNickName,
      state: teamMemberState.state
    }
    const result = await API.graphql(
      graphqlOperation(gqlMutations.setTeamMemberState, variables)
    )
    const updatedTeamItems = result.data.setTeamMemberState.items

    // updating my teams
    const updatedMyTeams = getUpdatedMyTeams(updatedTeamItems, state)
    commit('setMyTeams', updatedMyTeams)

    // updating team details
    const updatedTeamDetailsArrays = getUpdatedTeamDetails(
      updatedTeamItems,
      state
    )
    updatedTeamDetailsArrays.forEach((teamDetails) =>
      commit('appendToTeamDetails', teamDetails)
    )
  },
  async createTeam({ commit, state }, variables) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createNewTeam, variables)
    )
    const newTeam = result.data.createNewTeam
    console.debug('new team: ', newTeam)

    // updating my teams
    const myTeams = state.myTeams
    commit('setMyTeams', [newTeam].concat(myTeams))
  },
  async deleteTeamRelation({ commit, state }, teamRelation) {
    const variables = {
      teamName: teamRelation.teamName,
      userNickName: teamRelation.userNickName
    }
    const result = await API.graphql(
      graphqlOperation(gqlMutations.deleteTeamRelation, variables)
    )

    const deletedItem = result.data.deleteTeamRelation
    console.debug('deleted team relation: ', deletedItem)

    // updating my teams
    const updatedMyTeams = getUpdatedMyTeams([deletedItem], state, true)
    commit('setMyTeams', updatedMyTeams)

    // updating team details
    const updatedTeamDetailsArrays = getUpdatedTeamDetails(
      [deletedItem],
      state,
      true
    )
    updatedTeamDetailsArrays.forEach((teamDetails) =>
      commit('appendToTeamDetails', teamDetails)
    )
  },
  async requestTeamMembership({ commit, state }, variables) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.requestTeamMembership, variables)
    )
    const membershipRequest = result.data.requestTeamMembership
    console.debug('request membership: ', membershipRequest)

    // updating my teams
    const myTeams = state.myTeams
    commit('setMyTeams', [membershipRequest].concat(myTeams))
  },
  async listMyTeams({ commit }, filter) {
    const variables = {
      userNickName: filter.userNickName,
      state: filter.state,
      limit: 100,
      nextToken: ''
    }

    console.debug('Querying team by name with these options: ', variables)
    const gameScores = await API.graphql(
      graphqlOperation(queries.listMyTeams, variables)
    )
    const teamResults = gameScores.data.listMyTeams.items
    console.debug('Query result: ', teamResults)
    return commit('setMyTeams', teamResults)
  }
}
