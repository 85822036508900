import { API, graphqlOperation } from 'aws-amplify'

import * as gqlMutations from '../../src/graphql/mutations'
import * as queries from '../../src/graphql/queries'
import * as queriesCustom from '../../src/graphql/queries-custom'
import * as mutationsCustom from '../../src/graphql/mutations-custom'
import { compressSetOfGeoHashes } from '~/services/MapCalculations.js'

export const state = () => ({
  // all available games
  gameDefinitions: [],
  geoHashesAlreadyCheckedForGames: [],
  selectedGameDefinitionId: null,
  selectedGameDefinitionMaps: [],
  gameTypes: [
    {
      id: 'CLASSIC'
    },
    {
      id: 'LEVELS'
    }
  ],
  gameDefinitionStates: [
    {
      id: 'PRIVATE'
    },
    {
      id: 'PUBLIC'
    }
  ]
})

export const mutations = {
  appendGameDefinitions(state, newGameDefinitions) {
    const ids = state.gameDefinitions.map((m) => m.id)
    newGameDefinitions.forEach((newGameDefinition) => {
      if (!ids.includes(newGameDefinition.id)) {
        state.gameDefinitions.push(newGameDefinition)
        ids.push(newGameDefinition.id)
      }
    })
  },
  updateGameDefinition(state, updatedGameDefinition) {
    state.gameDefinitions = state.gameDefinitions.map((gameDefinition) => {
      if (gameDefinition.id === updatedGameDefinition.id) {
        return updatedGameDefinition
      }
      return gameDefinition
    })
  },
  addGameDefinition(state, newGameDefinition) {
    state.gameDefinitions.push(newGameDefinition)
  },
  setSelectedGameDefinition(state, gameDefinitionId) {
    state.selectedGameDefinitionId = gameDefinitionId

    // then update the selected maps
    const selectedGameDef = state.gameDefinitions.find(
      (gameDef) => gameDef.id === gameDefinitionId
    )

    if (selectedGameDef) {
      const firstPrimaryMap = selectedGameDef.omaps.find(
        (omap) => omap.primaryMap
      )
      const idVisibleMap = firstPrimaryMap
        ? firstPrimaryMap.id
        : selectedGameDef.omaps.length > 0
        ? selectedGameDef.omaps[0].id
        : null

      const newGameMaps = []
      selectedGameDef.omaps.forEach((omap) => {
        const extraMapProperties = {}
        if (omap.sourceType === 'TILES') {
          extraMapProperties.mapLocationUrl =
            omap.tileLocation + '{z}/{x}/{y}.gif'
        } else if (omap.sourceType === 'IMAGE') {
          extraMapProperties.bounds = [
            [omap.bottomLeftLat, omap.bottomLeftLng],
            [omap.topRightLat, omap.topRightLng]
          ]
        }
        extraMapProperties.visible = omap.id === idVisibleMap
        const newMap = Object.assign({}, omap, extraMapProperties)
        newGameMaps.push(newMap)
      })
      newGameMaps.push(this.state.map.osmLayer)
      newGameMaps.push(this.state.map.kartverketLayer)
      state.selectedGameDefinitionMaps = newGameMaps
    } else {
      const osmLayerVisible = Object.assign({}, this.state.map.osmLayer, {
        visible: true
      })
      state.selectedGameDefinitionMaps = [
        osmLayerVisible,
        this.state.map.kartverketLayer
      ]
    }
  },
  appendAllGeoHashesAlreadyCheckedForGames(state, newGeoHashesArray) {
    const geoHashesAlreadyCheckedForGamesSet = new Set(
      state.geoHashesAlreadyCheckedForGames
    )
    newGeoHashesArray.forEach((newGeoHash) =>
      geoHashesAlreadyCheckedForGamesSet.add(newGeoHash)
    )
    const compressedGeoHashes = compressSetOfGeoHashes(
      geoHashesAlreadyCheckedForGamesSet
    )
    state.geoHashesAlreadyCheckedForGames = [...compressedGeoHashes]
  }
}

const converters = {
  gameDefinition: {
    fromClientToServer: ({
      id,
      name,
      state,
      type,
      owner,
      startLat,
      startLng,
      startGeoHash
    }) => {
      const gameDefinition2 = Object.assign(
        {},
        {
          id,
          name,
          state,
          type,
          owner,
          startLat,
          startLng,
          startGeoHash
        }
      )
      return gameDefinition2
    },
    fromServerToClient: (gameDef) => {
      return Object.assign({}, gameDef, {
        omaps: gameDef.omaps.items.map((wrappedOmap) => wrappedOmap.omap)
      })
    }
  }
}

export const actions = {
  async reloadGameDefinition({ commit }, gameDefinitionId) {
    const options = {
      id: gameDefinitionId,
      limit: 1
    }

    const gameDefinitions = await API.graphql(
      graphqlOperation(queriesCustom.listGameDefinitionsWithMaps, options)
    )
    const gameDefinitions2 = gameDefinitions.data.listGameDefinitions.items.map(
      converters.gameDefinition.fromServerToClient
    )
    if (gameDefinitions2.length > 0) {
      commit('updateGameDefinition', gameDefinitions2[0])
      commit('setSelectedGameDefinition', gameDefinitionId)
    }
  },
  async getGameDefinitionsByLocation({ commit }, searchParams) {
    const options = {
      limit: 100,
      state: searchParams.state,
      startGeoHash: { beginsWith: searchParams.geoHash }
    }

    const gameDefinitions = await API.graphql(
      graphqlOperation(
        queriesCustom.gameDefinitionByStateByStartGeoHashWithMaps,
        options
      )
    )
    const gameDefinitions2 = gameDefinitions.data.gameDefinitionByStateByStartGeoHash.items.map(
      converters.gameDefinition.fromServerToClient
    )
    return commit('appendGameDefinitions', gameDefinitions2)
  },
  async addGameDefinition({ commit }, newGameDefinition) {
    const newGameDefinition2 = converters.gameDefinition.fromClientToServer(
      newGameDefinition
    )
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createGameDefinition, {
        input: newGameDefinition2
      })
    )
    const newGameDefinition3 = converters.gameDefinition.fromServerToClient(
      result.data.createGameDefinition
    )
    return commit('addGameDefinition', newGameDefinition3)
  },
  async updateGameDefinition({ commit }, updatedGameDefinition) {
    const updatedGameDefinition2 = converters.gameDefinition.fromClientToServer(
      updatedGameDefinition
    )
    const result = await API.graphql(
      graphqlOperation(mutationsCustom.updateGameDefinitionWithOmaps, {
        input: updatedGameDefinition2
      })
    )
    const updatedGameDefinition3 = converters.gameDefinition.fromServerToClient(
      result.data.updateGameDefinition
    )
    return commit('updateGameDefinition', updatedGameDefinition3)
  },

  async addMapToGame({ commit }, mapGameDefinition) {
    // create new map game connection
    await API.graphql(
      graphqlOperation(gqlMutations.createOmapGameDefinition, {
        input: mapGameDefinition
      })
    )

    // reload game definition with updated map
    await this.dispatch(
      'gameDefinitions/reloadGameDefinition',
      mapGameDefinition.gameDefinitionId
    )
  },
  async removeMapFromGame({ commit }, { omapId, gameDefinitionId }) {
    const mapGamesListResponse = await API.graphql(
      graphqlOperation(queries.omapGameDefinitionByGameDefinition, {
        omapId: { eq: omapId },
        gameDefinitionId,
        limit: 1
      })
    )

    const mapGames =
      mapGamesListResponse.data.omapGameDefinitionByGameDefinition.items
    if (mapGames.length === 1) {
      const mapGameId = mapGames[0].id
      await API.graphql(
        graphqlOperation(gqlMutations.deleteOmapGameDefinition, {
          input: { id: mapGameId }
        })
      )
    } else {
      // TODO could not find it
      console.log('TODO could not find it')
    }

    // reload game definition with updated map
    await this.dispatch(
      'gameDefinitions/reloadGameDefinition',
      gameDefinitionId
    )
  }
}
