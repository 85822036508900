export const updateGameDefinitionWithOmaps = /* GraphQL */ `
  mutation UpdateGameDefinition(
    $input: UpdateGameDefinitionInput!
    $condition: ModelGameDefinitionConditionInput
  ) {
    updateGameDefinition(input: $input, condition: $condition) {
      id
      name
      type
      startLat
      startLng
      startGeoHash
      state
      owner
      omaps {
        items {
          omap {
            id
            name
            tileLocation
            attribution
            primaryMap
            owner
          }
        }

        nextToken
      }
    }
  }
`
