/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listGameScoreRanking = /* GraphQL */ `
  query ListGameScoreRanking(
    $period: String!
    $state: String!
    $scoreType: GameScoreType
    $gameGeoHash: String
    $leagueId: String
    $ownerType: String
    $sortDirection: String
    $limit: Int
    $nextToken: String
  ) {
    listGameScoreRanking(
      period: $period
      state: $state
      scoreType: $scoreType
      gameGeoHash: $gameGeoHash
      leagueId: $leagueId
      ownerType: $ownerType
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        period
        currentPeriod
        owner
        ownerType
        scoreType
        gameGeoHash
        leagueId
        score
        ownerNickName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGameScoreByOwner = /* GraphQL */ `
  query ListGameScoreByOwner(
    $period: String!
    $state: String!
    $scoreType: GameScoreType
    $gameGeoHash: String
    $leagueId: String
    $ownerType: String
    $sortDirection: String
    $limit: Int
    $nextToken: String
  ) {
    listGameScoreByOwner(
      period: $period
      state: $state
      scoreType: $scoreType
      gameGeoHash: $gameGeoHash
      leagueId: $leagueId
      ownerType: $ownerType
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        period
        currentPeriod
        owner
        ownerType
        scoreType
        gameGeoHash
        leagueId
        score
        ownerNickName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchTeamNames = /* GraphQL */ `
  query SearchTeamNames(
    $teamNameQuery: String!
    $teamState: String
    $limit: Int
    $nextToken: String
  ) {
    searchTeamNames(
      teamNameQuery: $teamNameQuery
      teamState: $teamState
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamName
        teamState
        userNickName
        state
        lastActiveForTeam
      }
      nextToken
    }
  }
`;
export const getTeamDetails = /* GraphQL */ `
  query GetTeamDetails($teamName: String!, $teamState: String!) {
    getTeamDetails(teamName: $teamName, teamState: $teamState) {
      items {
        teamName
        teamState
        userNickName
        state
        lastActiveForTeam
      }
      nextToken
    }
  }
`;
export const listMyTeams = /* GraphQL */ `
  query ListMyTeams(
    $userNickName: String
    $state: String
    $limit: Int
    $nextToken: String
  ) {
    listMyTeams(
      userNickName: $userNickName
      state: $state
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamName
        teamState
        userNickName
        state
        lastActiveForTeam
      }
      nextToken
    }
  }
`;
export const listOmaps = /* GraphQL */ `
  query ListOmaps(
    $id: ID
    $filter: ModelOmapFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmaps(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOmap = /* GraphQL */ `
  query GetOmap($id: ID!) {
    getOmap(id: $id) {
      id
      name
      sourceType
      state
      tileLocation
      imageLocation
      attribution
      primaryMap
      bottomLeftLat
      bottomLeftLng
      topRightLat
      topRightLng
      geoHash
      maxZoom
      minZoom
      owner
      createdAt
      updatedAt
      gameDefinitions {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const omapByStateByGeoHash = /* GraphQL */ `
  query OmapByStateByGeoHash(
    $state: OmapState
    $geoHash: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    omapByStateByGeoHash(
      state: $state
      geoHash: $geoHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOmapGameDefinition = /* GraphQL */ `
  query GetOmapGameDefinition($id: ID!) {
    getOmapGameDefinition(id: $id) {
      id
      omapId
      gameDefinitionId
      createdAt
      updatedAt
      omap {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const listOmapGameDefinitions = /* GraphQL */ `
  query ListOmapGameDefinitions(
    $filter: ModelOmapGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOmapGameDefinitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omapId
        gameDefinitionId
        createdAt
        updatedAt
        omap {
          id
          name
          sourceType
          state
          tileLocation
          imageLocation
          attribution
          primaryMap
          bottomLeftLat
          bottomLeftLng
          topRightLat
          topRightLng
          geoHash
          maxZoom
          minZoom
          owner
          createdAt
          updatedAt
        }
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const omapGameDefinitionByOmap = /* GraphQL */ `
  query OmapGameDefinitionByOmap(
    $omapId: ID
    $gameDefinitionId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmapGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    omapGameDefinitionByOmap(
      omapId: $omapId
      gameDefinitionId: $gameDefinitionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omapId
        gameDefinitionId
        createdAt
        updatedAt
        omap {
          id
          name
          sourceType
          state
          tileLocation
          imageLocation
          attribution
          primaryMap
          bottomLeftLat
          bottomLeftLng
          topRightLat
          topRightLng
          geoHash
          maxZoom
          minZoom
          owner
          createdAt
          updatedAt
        }
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const omapGameDefinitionByGameDefinition = /* GraphQL */ `
  query OmapGameDefinitionByGameDefinition(
    $gameDefinitionId: ID
    $omapId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmapGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    omapGameDefinitionByGameDefinition(
      gameDefinitionId: $gameDefinitionId
      omapId: $omapId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omapId
        gameDefinitionId
        createdAt
        updatedAt
        omap {
          id
          name
          sourceType
          state
          tileLocation
          imageLocation
          attribution
          primaryMap
          bottomLeftLat
          bottomLeftLng
          topRightLat
          topRightLng
          geoHash
          maxZoom
          minZoom
          owner
          createdAt
          updatedAt
        }
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listGameDefinitions = /* GraphQL */ `
  query ListGameDefinitions(
    $id: ID
    $filter: ModelGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameDefinitions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGameDefinition = /* GraphQL */ `
  query GetGameDefinition($id: ID!) {
    getGameDefinition(id: $id) {
      id
      name
      type
      startGeoHash
      startLat
      startLng
      state
      owner
      createdAt
      updatedAt
      omaps {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const gameDefinitionByStateByStartGeoHash = /* GraphQL */ `
  query GameDefinitionByStateByStartGeoHash(
    $state: GameDefinitionState
    $startGeoHash: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameDefinitionByStateByStartGeoHash(
      state: $state
      startGeoHash: $startGeoHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listGameLevels = /* GraphQL */ `
  query ListGameLevels(
    $id: ID
    $filter: ModelGameLevelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameLevels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGameLevel = /* GraphQL */ `
  query GetGameLevel($id: ID!) {
    getGameLevel(id: $id) {
      id
      gameDefinitionId
      description
      order
      minNumberControls
      allowGpsPunching
      allowCodePunching
      minDistanceLegalPunch
      owner
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const gameLevelsByGameDefinitionIdByOrder = /* GraphQL */ `
  query GameLevelsByGameDefinitionIdByOrder(
    $gameDefinitionId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameLevelsByGameDefinitionIdByOrder(
      gameDefinitionId: $gameDefinitionId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGameLevelControl = /* GraphQL */ `
  query GetGameLevelControl($id: ID!) {
    getGameLevelControl(id: $id) {
      id
      gameLevelId
      gameDefinitionId
      controlId
      points
      owner
      createdAt
      updatedAt
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
    }
  }
`;
export const listGameLevelControls = /* GraphQL */ `
  query ListGameLevelControls(
    $id: ID
    $filter: ModelGameLevelControlFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameLevelControls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameLevelId
        gameDefinitionId
        controlId
        points
        owner
        createdAt
        updatedAt
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
      }
      nextToken
    }
  }
`;
export const gameLevelControlByGameLevelIdByControlId = /* GraphQL */ `
  query GameLevelControlByGameLevelIdByControlId(
    $gameLevelId: ID
    $controlId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameLevelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameLevelControlByGameLevelIdByControlId(
      gameLevelId: $gameLevelId
      controlId: $controlId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameLevelId
        gameDefinitionId
        controlId
        points
        owner
        createdAt
        updatedAt
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
      }
      nextToken
    }
  }
`;
export const gameLevelControlByGameLevelId = /* GraphQL */ `
  query GameLevelControlByGameLevelId(
    $gameLevelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGameLevelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameLevelControlByGameLevelId(
      gameLevelId: $gameLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameLevelId
        gameDefinitionId
        controlId
        points
        owner
        createdAt
        updatedAt
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
      }
      nextToken
    }
  }
`;
export const gameLevelControlByControlId = /* GraphQL */ `
  query GameLevelControlByControlId(
    $controlId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGameLevelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameLevelControlByControlId(
      controlId: $controlId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameLevelId
        gameDefinitionId
        controlId
        points
        owner
        createdAt
        updatedAt
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
      }
      nextToken
    }
  }
`;
export const gameLevelControlByGameDefinitionId = /* GraphQL */ `
  query GameLevelControlByGameDefinitionId(
    $gameDefinitionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGameLevelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameLevelControlByGameDefinitionId(
      gameDefinitionId: $gameDefinitionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameLevelId
        gameDefinitionId
        controlId
        points
        owner
        createdAt
        updatedAt
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
      }
      nextToken
    }
  }
`;
export const controlsByOwner = /* GraphQL */ `
  query ControlsByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      nextToken
    }
  }
`;
export const getControl = /* GraphQL */ `
  query GetControl($id: ID!) {
    getControl(id: $id) {
      id
      visualCode
      lat
      lng
      owner
      createdAt
      updatedAt
      secretCode
    }
  }
`;
export const listControls = /* GraphQL */ `
  query ListControls(
    $id: ID
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listControls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      nextToken
    }
  }
`;
export const listGameStates = /* GraphQL */ `
  query ListGameStates(
    $id: ID
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameStates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGameState = /* GraphQL */ `
  query GetGameState($id: ID!) {
    getGameState(id: $id) {
      id
      gameId
      gameDefinitionId
      owner
      progress
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const gameStatesByOwnerByProgress = /* GraphQL */ `
  query GameStatesByOwnerByProgress(
    $owner: String
    $progress: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatesByOwnerByProgress(
      owner: $owner
      progress: $progress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const gameStatesByOwnerByGameId = /* GraphQL */ `
  query GameStatesByOwnerByGameId(
    $owner: String
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatesByOwnerByGameId(
      owner: $owner
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const gameStatesByOwnerByGameDefinitionByProgress = /* GraphQL */ `
  query GameStatesByOwnerByGameDefinitionByProgress(
    $owner: String
    $gameDefinitionIdProgress: ModelGameStateByOwnerByGameDefinitionByProgressCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatesByOwnerByGameDefinitionByProgress(
      owner: $owner
      gameDefinitionIdProgress: $gameDefinitionIdProgress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const gameStatesByGameDefinitionIdByProgressByUpdatedAt = /* GraphQL */ `
  query GameStatesByGameDefinitionIdByProgressByUpdatedAt(
    $gameDefinitionId: String
    $progressUpdatedAt: ModelGameStateByGameDefinitionIdByProgressByUpdatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatesByGameDefinitionIdByProgressByUpdatedAt(
      gameDefinitionId: $gameDefinitionId
      progressUpdatedAt: $progressUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getControlVisit = /* GraphQL */ `
  query GetControlVisit($id: ID!) {
    getControlVisit(id: $id) {
      id
      gameStateId
      gameStateGameId
      controlId
      controlSecretCode
      controlVisualCode
      controlPoints
      lat
      lng
      accuracy
      owner
      createdAt
      updatedAt
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      gameState {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listControlVisits = /* GraphQL */ `
  query ListControlVisits(
    $id: ID
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listControlVisits(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const controlVisitsByOwnerByControlId = /* GraphQL */ `
  query ControlVisitsByOwnerByControlId(
    $owner: String
    $controlId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByControlId(
      owner: $owner
      controlId: $controlId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const controlVisitsByGameStateGameId = /* GraphQL */ `
  query ControlVisitsByGameStateGameId(
    $gameStateGameId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByGameStateGameId(
      gameStateGameId: $gameStateGameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const controlVisitsByOwnerByGameStateGameId = /* GraphQL */ `
  query ControlVisitsByOwnerByGameStateGameId(
    $owner: String
    $gameStateGameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByGameStateGameId(
      owner: $owner
      gameStateGameId: $gameStateGameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const controlVisitsByOwnerByGameStateId = /* GraphQL */ `
  query ControlVisitsByOwnerByGameStateId(
    $owner: String
    $gameStateId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByGameStateId(
      owner: $owner
      gameStateId: $gameStateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
          secretCode
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGameScore = /* GraphQL */ `
  query GetGameScore($id: ID!) {
    getGameScore(id: $id) {
      id
      state
      period
      currentPeriod
      owner
      ownerType
      scoreType
      gameGeoHash
      leagueId
      score
      ownerNickName
      createdAt
      updatedAt
    }
  }
`;
export const listGameScores = /* GraphQL */ `
  query ListGameScores(
    $id: ID
    $filter: ModelGameScoreFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameScores(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        state
        period
        currentPeriod
        owner
        ownerType
        scoreType
        gameGeoHash
        leagueId
        score
        ownerNickName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameScoreByPeriodByStateByScoreTypeByGameGeoHashByLeagueByOwnerTypeByScore = /* GraphQL */ `
  query GameScoreByPeriodByStateByScoreTypeByGameGeoHashByLeagueByOwnerTypeByScore(
    $period: String
    $stateScoreTypeGameGeoHashLeagueIdOwnerTypeScore: ModelGameScoreByPeriodByStateByScoreTypeByGameGeoHashByLeagueByOwnerTypeByScoreCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameScoreByPeriodByStateByScoreTypeByGameGeoHashByLeagueByOwnerTypeByScore(
      period: $period
      stateScoreTypeGameGeoHashLeagueIdOwnerTypeScore: $stateScoreTypeGameGeoHashLeagueIdOwnerTypeScore
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        period
        currentPeriod
        owner
        ownerType
        scoreType
        gameGeoHash
        leagueId
        score
        ownerNickName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameScoreByOwnerByStateByCurrentPeriodByGameGeoHashByPeriodByScoreType = /* GraphQL */ `
  query GameScoreByOwnerByStateByCurrentPeriodByGameGeoHashByPeriodByScoreType(
    $owner: String
    $stateCurrentPeriodGameGeoHashPeriodScoreType: ModelGameScoreByOwnerByStateByCurrentPeriodByGameGeoHashByPeriodByScoreTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameScoreByOwnerByStateByCurrentPeriodByGameGeoHashByPeriodByScoreType(
      owner: $owner
      stateCurrentPeriodGameGeoHashPeriodScoreType: $stateCurrentPeriodGameGeoHashPeriodScoreType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        period
        currentPeriod
        owner
        ownerType
        scoreType
        gameGeoHash
        leagueId
        score
        ownerNickName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      teamName
      teamState
      teamNameLowerCase
      userId
      userNickName
      state
      lastActiveForTeam
      version
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $id: ID
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeams(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teamName
        teamState
        teamNameLowerCase
        userId
        userNickName
        state
        lastActiveForTeam
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByUserNickNameByState = /* GraphQL */ `
  query TeamByUserNickNameByState(
    $userNickName: String
    $state: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByUserNickNameByState(
      userNickName: $userNickName
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamName
        teamState
        teamNameLowerCase
        userId
        userNickName
        state
        lastActiveForTeam
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByTeamStateByTeamNameLowerCase = /* GraphQL */ `
  query TeamByTeamStateByTeamNameLowerCase(
    $teamState: String
    $teamNameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByTeamStateByTeamNameLowerCase(
      teamState: $teamState
      teamNameLowerCase: $teamNameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamName
        teamState
        teamNameLowerCase
        userId
        userNickName
        state
        lastActiveForTeam
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
