/**
 * Same as gameLevelControlByGameDefinitionId, without the secretCode field.
 * @type {string}
 */
export const listGameLevelControlByGameDefinitionIdWithoutSecret = `query GameLevelControlByGameDefinitionId(
  $gameDefinitionId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelGameLevelControlFilterInput
  $limit: Int
  $nextToken: String
) {
  gameLevelControlByGameDefinitionId(
    gameDefinitionId: $gameDefinitionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      gameLevelId
      gameDefinitionId
      controlId
      owner
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        owner
      }
      control {
        id
        visualCode
        lat
        lng
        owner
      }
    }
    nextToken
  }
}
`

export const gameLevelControlByGameLevelIdWithoutSecret = `query GameLevelControlByGameLevelId(
  $gameLevelId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelGameLevelControlFilterInput
  $limit: Int
  $nextToken: String
) {
  gameLevelControlByGameLevelId(
    gameLevelId: $gameLevelId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      gameLevelId
      controlId
      points
      owner
      gameLevel {
                id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
      }
      control {
        id
        visualCode
        lat
        lng
        owner
      }
    }
    nextToken
  }
}
`

export const controlVisitsByOwnerByGameStateIdWithoutSecret = `query ControlVisitsByOwnerByGameStateId(
    $owner: String
    $gameStateId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByGameStateId(
      owner: $owner
      gameStateId: $gameStateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlSecretCode
        lat
        lng
        accuracy
        owner
        createdAt
        control {
          id
          visualCode
          lat
          lng
          owner
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`

export const controlVisitsByOwnerWithoutSecret = `
  query ControlVisitsByOwnerByControlId(
    $owner: String
    $controlId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByControlId(
      owner: $owner
      controlId: $controlId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlId
        controlSecretCode
        controlVisualCode
        controlPoints
        lat
        lng
        accuracy
        owner
        createdAt
        updatedAt
        control {
          id
          visualCode
          lat
          lng
          owner
          createdAt
          updatedAt
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
          gameDefinition {
            name
            type
            startGeoHash
            startLat
            startLng
            state
            owner
          }
          gameLevel {
            description
            order
            minNumberControls
            allowGpsPunching
            allowCodePunching
            minDistanceLegalPunch
            owner
          }	
        }
      }
      nextToken
    }
  }
`

export const gameDefinitionByStateByStartGeoHashWithMaps = /* GraphQL */ `
  query GameDefinitionByStateByStartGeoHash(
    $state: GameDefinitionState
    $startGeoHash: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameDefinitionByStateByStartGeoHash(
      state: $state
      startGeoHash: $startGeoHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        omaps {
          items {
            omap {
              id
              name
              sourceType
              state
              tileLocation
              imageLocation
              attribution
              primaryMap
              bottomLeftLat
              bottomLeftLng
              topRightLat
              topRightLng
              geoHash
              maxZoom
              minZoom
              owner
            }
          }

          nextToken
        }
      }
      nextToken
    }
  }
`

export const listGameDefinitionsWithMaps = /* GraphQL */ `
  query ListGameDefinitions(
    $id: ID
    $filter: ModelGameDefinitionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameDefinitions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        startLat
        startLng
        startGeoHash
        state
        owner
        omaps {
          items {
            omap {
              id
              name
              sourceType
              state
              tileLocation
              imageLocation
              attribution
              primaryMap
              bottomLeftLat
              bottomLeftLng
              topRightLat
              topRightLng
              geoHash
              maxZoom
              minZoom
              owner
            }
          }

          nextToken
        }
      }
      nextToken
    }
  }
`

export const controlVisitsByOwnerByGameStateGameIdWithoutSecret = /* GraphQL */ `
  query ControlVisitsByOwnerByGameStateGameId(
    $owner: String
    $gameStateGameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlVisitsByOwnerByGameStateGameId(
      owner: $owner
      gameStateGameId: $gameStateGameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameStateId
        gameStateGameId
        controlSecretCode
        lat
        lng
        accuracy
        owner
        createdAt
        control {
          id
          visualCode
          lat
          lng
          owner
        }
        gameState {
          id
          gameId
          gameDefinitionId
          owner
          progress
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
