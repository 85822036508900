/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const punchAtControl = /* GraphQL */ `
  mutation PunchAtControl(
    $controlId: String!
    $gameStateId: String!
    $controlSecretCode: String
    $lat: Float
    $lng: Float
    $accuracy: Float
  ) {
    punchAtControl(
      controlId: $controlId
      gameStateId: $gameStateId
      controlSecretCode: $controlSecretCode
      lat: $lat
      lng: $lng
      accuracy: $accuracy
    ) {
      controlVisit {
        id
        controlSecretCode
        owner
        lat
        lng
        accuracy
      }
      gameStates {
        id
        gameId
        gameDefinitionId
        progress
        gameLevel {
          id
          minNumberControls
          order
        }
        owner
      }
    }
  }
`;
export const startNewGame = /* GraphQL */ `
  mutation StartNewGame($gameId: ID!, $gameDefinitionId: ID!) {
    startNewGame(gameId: $gameId, gameDefinitionId: $gameDefinitionId) {
      id
      gameId
      gameDefinitionId
      progress
      gameLevel {
        id
        minNumberControls
        order
      }
      owner
    }
  }
`;
export const cancelGame = /* GraphQL */ `
  mutation CancelGame($gameId: ID!) {
    cancelGame(gameId: $gameId) {
      id
      gameId
      gameDefinitionId
      progress
      gameLevel {
        id
        minNumberControls
        order
      }
      owner
    }
  }
`;
export const deleteGameScores = /* GraphQL */ `
  mutation DeleteGameScores($owner: ID!) {
    deleteGameScores(owner: $owner) {
      deletionCount
    }
  }
`;
export const createNewTeam = /* GraphQL */ `
  mutation CreateNewTeam($teamName: String!) {
    createNewTeam(teamName: $teamName) {
      teamName
      teamState
      userNickName
      state
      lastActiveForTeam
    }
  }
`;
export const requestTeamMembership = /* GraphQL */ `
  mutation RequestTeamMembership($teamName: String!) {
    requestTeamMembership(teamName: $teamName) {
      teamName
      teamState
      userNickName
      state
      lastActiveForTeam
    }
  }
`;
export const deleteTeamRelation = /* GraphQL */ `
  mutation DeleteTeamRelation($teamName: String!, $userNickName: String!) {
    deleteTeamRelation(teamName: $teamName, userNickName: $userNickName) {
      teamName
      teamState
      userNickName
      state
      lastActiveForTeam
    }
  }
`;
export const setTeamMemberState = /* GraphQL */ `
  mutation SetTeamMemberState(
    $teamName: String!
    $userNickName: String!
    $state: String!
  ) {
    setTeamMemberState(
      teamName: $teamName
      userNickName: $userNickName
      state: $state
    ) {
      items {
        teamName
        teamState
        userNickName
        state
        lastActiveForTeam
      }
      nextToken
    }
  }
`;
export const createOmap = /* GraphQL */ `
  mutation CreateOmap(
    $input: CreateOmapInput!
    $condition: ModelOmapConditionInput
  ) {
    createOmap(input: $input, condition: $condition) {
      id
      name
      sourceType
      state
      tileLocation
      imageLocation
      attribution
      primaryMap
      bottomLeftLat
      bottomLeftLng
      topRightLat
      topRightLng
      geoHash
      maxZoom
      minZoom
      owner
      createdAt
      updatedAt
      gameDefinitions {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOmap = /* GraphQL */ `
  mutation UpdateOmap(
    $input: UpdateOmapInput!
    $condition: ModelOmapConditionInput
  ) {
    updateOmap(input: $input, condition: $condition) {
      id
      name
      sourceType
      state
      tileLocation
      imageLocation
      attribution
      primaryMap
      bottomLeftLat
      bottomLeftLng
      topRightLat
      topRightLng
      geoHash
      maxZoom
      minZoom
      owner
      createdAt
      updatedAt
      gameDefinitions {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOmap = /* GraphQL */ `
  mutation DeleteOmap(
    $input: DeleteOmapInput!
    $condition: ModelOmapConditionInput
  ) {
    deleteOmap(input: $input, condition: $condition) {
      id
      name
      sourceType
      state
      tileLocation
      imageLocation
      attribution
      primaryMap
      bottomLeftLat
      bottomLeftLng
      topRightLat
      topRightLng
      geoHash
      maxZoom
      minZoom
      owner
      createdAt
      updatedAt
      gameDefinitions {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOmapGameDefinition = /* GraphQL */ `
  mutation CreateOmapGameDefinition(
    $input: CreateOmapGameDefinitionInput!
    $condition: ModelOmapGameDefinitionConditionInput
  ) {
    createOmapGameDefinition(input: $input, condition: $condition) {
      id
      omapId
      gameDefinitionId
      createdAt
      updatedAt
      omap {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const updateOmapGameDefinition = /* GraphQL */ `
  mutation UpdateOmapGameDefinition(
    $input: UpdateOmapGameDefinitionInput!
    $condition: ModelOmapGameDefinitionConditionInput
  ) {
    updateOmapGameDefinition(input: $input, condition: $condition) {
      id
      omapId
      gameDefinitionId
      createdAt
      updatedAt
      omap {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const deleteOmapGameDefinition = /* GraphQL */ `
  mutation DeleteOmapGameDefinition(
    $input: DeleteOmapGameDefinitionInput!
    $condition: ModelOmapGameDefinitionConditionInput
  ) {
    deleteOmapGameDefinition(input: $input, condition: $condition) {
      id
      omapId
      gameDefinitionId
      createdAt
      updatedAt
      omap {
        id
        name
        sourceType
        state
        tileLocation
        imageLocation
        attribution
        primaryMap
        bottomLeftLat
        bottomLeftLng
        topRightLat
        topRightLng
        geoHash
        maxZoom
        minZoom
        owner
        createdAt
        updatedAt
        gameDefinitions {
          nextToken
        }
      }
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const createGameDefinition = /* GraphQL */ `
  mutation CreateGameDefinition(
    $input: CreateGameDefinitionInput!
    $condition: ModelGameDefinitionConditionInput
  ) {
    createGameDefinition(input: $input, condition: $condition) {
      id
      name
      type
      startGeoHash
      startLat
      startLng
      state
      owner
      createdAt
      updatedAt
      omaps {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGameDefinition = /* GraphQL */ `
  mutation UpdateGameDefinition(
    $input: UpdateGameDefinitionInput!
    $condition: ModelGameDefinitionConditionInput
  ) {
    updateGameDefinition(input: $input, condition: $condition) {
      id
      name
      type
      startGeoHash
      startLat
      startLng
      state
      owner
      createdAt
      updatedAt
      omaps {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGameDefinition = /* GraphQL */ `
  mutation DeleteGameDefinition(
    $input: DeleteGameDefinitionInput!
    $condition: ModelGameDefinitionConditionInput
  ) {
    deleteGameDefinition(input: $input, condition: $condition) {
      id
      name
      type
      startGeoHash
      startLat
      startLng
      state
      owner
      createdAt
      updatedAt
      omaps {
        items {
          id
          omapId
          gameDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGameLevel = /* GraphQL */ `
  mutation CreateGameLevel(
    $input: CreateGameLevelInput!
    $condition: ModelGameLevelConditionInput
  ) {
    createGameLevel(input: $input, condition: $condition) {
      id
      gameDefinitionId
      description
      order
      minNumberControls
      allowGpsPunching
      allowCodePunching
      minDistanceLegalPunch
      owner
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const updateGameLevel = /* GraphQL */ `
  mutation UpdateGameLevel(
    $input: UpdateGameLevelInput!
    $condition: ModelGameLevelConditionInput
  ) {
    updateGameLevel(input: $input, condition: $condition) {
      id
      gameDefinitionId
      description
      order
      minNumberControls
      allowGpsPunching
      allowCodePunching
      minDistanceLegalPunch
      owner
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const deleteGameLevel = /* GraphQL */ `
  mutation DeleteGameLevel(
    $input: DeleteGameLevelInput!
    $condition: ModelGameLevelConditionInput
  ) {
    deleteGameLevel(input: $input, condition: $condition) {
      id
      gameDefinitionId
      description
      order
      minNumberControls
      allowGpsPunching
      allowCodePunching
      minDistanceLegalPunch
      owner
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
    }
  }
`;
export const createGameLevelControl = /* GraphQL */ `
  mutation CreateGameLevelControl(
    $input: CreateGameLevelControlInput!
    $condition: ModelGameLevelControlConditionInput
  ) {
    createGameLevelControl(input: $input, condition: $condition) {
      id
      gameLevelId
      gameDefinitionId
      controlId
      points
      owner
      createdAt
      updatedAt
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
    }
  }
`;
export const updateGameLevelControl = /* GraphQL */ `
  mutation UpdateGameLevelControl(
    $input: UpdateGameLevelControlInput!
    $condition: ModelGameLevelControlConditionInput
  ) {
    updateGameLevelControl(input: $input, condition: $condition) {
      id
      gameLevelId
      gameDefinitionId
      controlId
      points
      owner
      createdAt
      updatedAt
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
    }
  }
`;
export const deleteGameLevelControl = /* GraphQL */ `
  mutation DeleteGameLevelControl(
    $input: DeleteGameLevelControlInput!
    $condition: ModelGameLevelControlConditionInput
  ) {
    deleteGameLevelControl(input: $input, condition: $condition) {
      id
      gameLevelId
      gameDefinitionId
      controlId
      points
      owner
      createdAt
      updatedAt
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
    }
  }
`;
export const createControl = /* GraphQL */ `
  mutation CreateControl(
    $input: CreateControlInput!
    $condition: ModelControlConditionInput
  ) {
    createControl(input: $input, condition: $condition) {
      id
      visualCode
      lat
      lng
      owner
      createdAt
      updatedAt
      secretCode
    }
  }
`;
export const updateControl = /* GraphQL */ `
  mutation UpdateControl(
    $input: UpdateControlInput!
    $condition: ModelControlConditionInput
  ) {
    updateControl(input: $input, condition: $condition) {
      id
      visualCode
      lat
      lng
      owner
      createdAt
      updatedAt
      secretCode
    }
  }
`;
export const deleteControl = /* GraphQL */ `
  mutation DeleteControl(
    $input: DeleteControlInput!
    $condition: ModelControlConditionInput
  ) {
    deleteControl(input: $input, condition: $condition) {
      id
      visualCode
      lat
      lng
      owner
      createdAt
      updatedAt
      secretCode
    }
  }
`;
export const deleteGameState = /* GraphQL */ `
  mutation DeleteGameState(
    $input: DeleteGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    deleteGameState(input: $input, condition: $condition) {
      id
      gameId
      gameDefinitionId
      owner
      progress
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateControlVisit = /* GraphQL */ `
  mutation UpdateControlVisit(
    $input: UpdateControlVisitInput!
    $condition: ModelControlVisitConditionInput
  ) {
    updateControlVisit(input: $input, condition: $condition) {
      id
      gameStateId
      gameStateGameId
      controlId
      controlSecretCode
      controlVisualCode
      controlPoints
      lat
      lng
      accuracy
      owner
      createdAt
      updatedAt
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      gameState {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteControlVisit = /* GraphQL */ `
  mutation DeleteControlVisit(
    $input: DeleteControlVisitInput!
    $condition: ModelControlVisitConditionInput
  ) {
    deleteControlVisit(input: $input, condition: $condition) {
      id
      gameStateId
      gameStateGameId
      controlId
      controlSecretCode
      controlVisualCode
      controlPoints
      lat
      lng
      accuracy
      owner
      createdAt
      updatedAt
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      gameState {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGameState = /* GraphQL */ `
  mutation CreateGameState(
    $input: CreateGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    createGameState(input: $input, condition: $condition) {
      id
      gameId
      gameDefinitionId
      owner
      progress
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGameState = /* GraphQL */ `
  mutation UpdateGameState(
    $input: UpdateGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    updateGameState(input: $input, condition: $condition) {
      id
      gameId
      gameDefinitionId
      owner
      progress
      createdAt
      updatedAt
      gameDefinition {
        id
        name
        type
        startGeoHash
        startLat
        startLng
        state
        owner
        createdAt
        updatedAt
        omaps {
          nextToken
        }
      }
      gameLevel {
        id
        gameDefinitionId
        description
        order
        minNumberControls
        allowGpsPunching
        allowCodePunching
        minDistanceLegalPunch
        owner
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createControlVisit = /* GraphQL */ `
  mutation CreateControlVisit(
    $input: CreateControlVisitInput!
    $condition: ModelControlVisitConditionInput
  ) {
    createControlVisit(input: $input, condition: $condition) {
      id
      gameStateId
      gameStateGameId
      controlId
      controlSecretCode
      controlVisualCode
      controlPoints
      lat
      lng
      accuracy
      owner
      createdAt
      updatedAt
      control {
        id
        visualCode
        lat
        lng
        owner
        createdAt
        updatedAt
        secretCode
      }
      gameState {
        id
        gameId
        gameDefinitionId
        owner
        progress
        createdAt
        updatedAt
        gameDefinition {
          id
          name
          type
          startGeoHash
          startLat
          startLng
          state
          owner
          createdAt
          updatedAt
        }
        gameLevel {
          id
          gameDefinitionId
          description
          order
          minNumberControls
          allowGpsPunching
          allowCodePunching
          minDistanceLegalPunch
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGameScore = /* GraphQL */ `
  mutation CreateGameScore(
    $input: CreateGameScoreInput!
    $condition: ModelGameScoreConditionInput
  ) {
    createGameScore(input: $input, condition: $condition) {
      id
      state
      period
      currentPeriod
      owner
      ownerType
      scoreType
      gameGeoHash
      leagueId
      score
      ownerNickName
      createdAt
      updatedAt
    }
  }
`;
export const updateGameScore = /* GraphQL */ `
  mutation UpdateGameScore(
    $input: UpdateGameScoreInput!
    $condition: ModelGameScoreConditionInput
  ) {
    updateGameScore(input: $input, condition: $condition) {
      id
      state
      period
      currentPeriod
      owner
      ownerType
      scoreType
      gameGeoHash
      leagueId
      score
      ownerNickName
      createdAt
      updatedAt
    }
  }
`;
export const deleteGameScore = /* GraphQL */ `
  mutation DeleteGameScore(
    $input: DeleteGameScoreInput!
    $condition: ModelGameScoreConditionInput
  ) {
    deleteGameScore(input: $input, condition: $condition) {
      id
      state
      period
      currentPeriod
      owner
      ownerType
      scoreType
      gameGeoHash
      leagueId
      score
      ownerNickName
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      teamName
      teamState
      teamNameLowerCase
      userId
      userNickName
      state
      lastActiveForTeam
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      teamName
      teamState
      teamNameLowerCase
      userId
      userNickName
      state
      lastActiveForTeam
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      teamName
      teamState
      teamNameLowerCase
      userId
      userNickName
      state
      lastActiveForTeam
      version
      createdAt
      updatedAt
    }
  }
`;
