import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

// this api key will be visible for all users, since the code runs in users browser
const bugsnagClient = bugsnag({
  apiKey: 'c584bd6b63c9ac98e0871706862772d9',
  appVersion: '0.4.11',
  filters: [/^authorization$/i, /^password$/i]
})
bugsnagClient.use(bugsnagVue, Vue)

export default function({ app }, inject) {
  inject('bugsnag', bugsnagClient)
}
