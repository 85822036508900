import { API, graphqlOperation } from 'aws-amplify'
import * as gqlMutations from '../../src/graphql/mutations'
import * as queries from '../../src/graphql/queries'

export const state = () => ({
  // the current game state the user has selected
  currentGameState: null,
  // all states belonging to the current game (one per level)
  currentGameStates: [],
  // all unfinished games that the user is playing
  ongoingGameStates: [],
  celebrationEvent: null
})

export const mutations = {
  setCurrentGameState(state, gameState) {
    state.currentGameState = gameState
  },
  setOngoingGameStates(state, gameStates) {
    state.ongoingGameStates = gameStates
  },
  setCurrentGameStates(state, gameStates) {
    state.currentGameStates = gameStates
  },
  setCelebrationEvent(state, celebrationEvent) {
    state.celebrationEvent = celebrationEvent
  },
  resetStateOnGameCompletion(state) {
    state.currentGameState = null
    state.currentGameStates = []
    state.ongoingGameStates = []
  },
  clearCelebrationEvent(state) {
    state.celebrationEvent = null
  }
}

export const actions = {
  async createGameState({ commit }, newGameState) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.startNewGame, newGameState)
    )

    const newGameState2 = converters.gameState.fromServerToClient(
      result.data.startNewGame
    )

    // it is only one state to begin with
    commit('setCurrentGameStates', [newGameState2])
    return commit('setCurrentGameState', newGameState2)
  },
  async getGameState({ commit }, gameStateId) {
    const result = await API.graphql(
      graphqlOperation(queries.getGameState, {
        id: gameStateId
      })
    )

    const gameState = converters.gameState.fromServerToClient(
      result.data.getGameState
    )

    return commit('setCurrentGameState', gameState)
  },
  /**
   * Cancelling a game has the following consequences:
   * - remove from ongoing games list
   * - remove from current ongoing game - if used
   * - remove loaded game info (map / controls / level info)
   * @param commit
   * @param gameId
   * @returns {Promise<*>}
   */
  async cancelGame({ commit }, gameId) {
    const options = {
      gameId
    }
    // const updatedGameStates =
    await API.graphql(graphqlOperation(gqlMutations.cancelGame, options))

    const ongoingGameStates2 = this.state.gameState.ongoingGameStates.filter(
      (ongoingGameStates) => ongoingGameStates.gameId !== gameId
    )

    return commit('setOngoingGameStates', ongoingGameStates2)
  },
  async getOngoingGameStates({ commit }, { owner }) {
    const options = {
      owner,
      progress: {
        eq: 'ONGOING'
      },
      limit: 100
    }
    const result = await API.graphql(
      graphqlOperation(queries.gameStatesByOwnerByProgress, options)
    )
    const ongoingGameStates = result.data.gameStatesByOwnerByProgress.items.map(
      converters.gameState.fromServerToClient
    )
    return commit('setOngoingGameStates', ongoingGameStates)
  },
  async getGameStatesByOwnerByGameId({ commit }, { owner, gameId }) {
    const options = {
      owner,
      gameId: {
        eq: gameId
      }
    }
    const result = await API.graphql(
      graphqlOperation(queries.gameStatesByOwnerByGameId, options)
    )
    const gameStatesForCurrentGame = result.data.gameStatesByOwnerByGameId.items.map(
      converters.gameState.fromServerToClient
    )
    return commit('setCurrentGameStates', gameStatesForCurrentGame)
  },
  async punchControl({ commit }, controlVisit) {
    const punchResponse = await API.graphql(
      graphqlOperation(gqlMutations.punchAtControl, controlVisit)
    )
    const updatedGameStates = punchResponse.data.punchAtControl.gameStates

    if (
      updatedGameStates.filter((gameState) => gameState.progress !== 'COMPLETE')
        .length === 0
    ) {
      // all levels complete - give applause
      commit('setCelebrationEvent', {
        message: 'all-levels-completed'
      })
      // TODO if I reset here, we cannot show the game status afterwards. Do I need to do this?
      // it might be nice for the user to see the controls on the map still
      // commit('resetStateOnGameCompletion')
      // this.commit('gameLevels/resetStateOnGameCompletion')
      // this.commit('map/resetStateOnGameCompletion')
    } else {
      //
      const currentGameStateIds = this.state.gameState.currentGameStates.map(
        (gameState) => gameState.id
      )
      const newGameStates = updatedGameStates.filter(
        (updatedGameState) => !currentGameStateIds.includes(updatedGameState.id)
      )
      switch (newGameStates.length) {
        case 1:
          // one new level unlocked, set it as current state
          const newGameState = newGameStates[0]
          commit('setCurrentGameState', newGameState)
          await this.dispatch(
            'gameLevels/getGameLevel',
            this.state.gameState.currentGameState.gameLevel.id
          )
          commit('setCelebrationEvent', {
            message: 'new-level-unlocked'
          })
          break
        case 0:
          // no new level unlocked
          break
        default:
          // should not happen, we cannot get two new states in one control punch -> throw exception
          // TODO better error msg
          throw new Error('should not happen')
      }
    }

    commit('setCurrentGameStates', updatedGameStates)
  }
}

const converters = {
  gameState: {
    fromServerToClient: (gameState) => gameState
  }
}
