import geohash from 'ngeohash'
import { getDistance, getCenter } from 'geolib'

const GEO_HASH_PRECISION_GAME_DEF = 10

export function getDistanceInMeters(latLng1, latLng2) {
  return getDistance(latLng1, latLng2)
}

export function getCenterOfMap(omap) {
  return getCenterInList([
    [omap.bottomLeftLat, omap.bottomLeftLng],
    [omap.topRightLat, omap.topRightLng]
  ])
}

function getUtm({ a, d, b, e, c, f }, { x, y }) {
  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  }
  const utmX = a * x + b * y + c
  const utmY = d * x + e * y + f

  return [round(utmX, 6), round(utmY, 6)]
}

export function parseWorldFile(linesArray, imagePixelDimensions) {
  if (linesArray.length < 6 || imagePixelDimensions.length !== 2) {
    throw new UnexpectedInputError()
  }
  const a = Number(linesArray[0])
  const d = Number(linesArray[1])
  const b = Number(linesArray[2])
  const e = Number(linesArray[3])
  const c = Number(linesArray[4])
  const f = Number(linesArray[5])

  const lowerLeft = {
    x: 0,
    y: imagePixelDimensions[1]
  }
  const upperRight = {
    x: imagePixelDimensions[0],
    y: 0
  }
  const utmLowerLeft = getUtm({ a, d, b, e, c, f }, lowerLeft)
  const utmUpperRight = getUtm({ a, d, b, e, c, f }, upperRight)

  return [utmLowerLeft, utmUpperRight]
}

function getCenterInList(latLngs) {
  const { longitude, latitude } = getCenter(latLngs)
  return [longitude, latitude]
}

/**
 * WithinMeters = 10
 * DistanceInMeters = 15
 * Accuracy = 8
 *
 * IsCloseEnough = 15 - 8 <= 10: Yes
 */
export function isCloseEnough(
  { latitude, longitude, accuracy },
  { latitude2, longitude2 },
  withinMeters
) {
  const distanceInM = getDistanceInMeters(
    [latitude, longitude],
    [latitude2, longitude2]
  )
  const distanceFromControl = distanceInM - Math.min(accuracy, 25)
  const isCloseEnough = distanceFromControl <= withinMeters
  return { isCloseEnough, distanceFromControl }
}

function canSearch(zoomLevel) {
  return zoomLevel >= 11
}

function getPrecision(precisionOverride) {
  if (precisionOverride) {
    return precisionOverride
  }
  return 4
}

function getGeoHashesInCurrentBounds(
  theCurrentZoom,
  theCurrentBounds,
  precisionOverride
) {
  const geoHashPrecision = getPrecision(precisionOverride)

  const geoHashesInCurrentBounds = geohash.bboxes(
    theCurrentBounds._southWest.lat,
    theCurrentBounds._southWest.lng,
    theCurrentBounds._northEast.lat,
    theCurrentBounds._northEast.lng,
    geoHashPrecision
  )
  return geoHashesInCurrentBounds
}

export function getSwNeCoordinatesForGeoHashesInCurrentBounds(
  theCurrentZoom,
  theCurrentBounds,
  precisionOverride
) {
  const geoHashesInCurrentBounds = getGeoHashesInCurrentBounds(
    theCurrentZoom,
    theCurrentBounds,
    precisionOverride
  )

  return geoHashesInCurrentBounds.map((bbIn) => {
    const bb = geohash.decode_bbox(bbIn)

    return {
      geoHash: bbIn,
      boundingBox: [
        [bb[0], bb[1]],
        [bb[2], bb[3]]
      ]
    }
  })
}

export function getControlRadius(theCurrentZoom, extraRadius) {
  const additionalRadius = extraRadius || 0
  if (!theCurrentZoom) {
    return 20 + additionalRadius
  }
  let radius = 0
  switch (theCurrentZoom) {
    case 12:
      radius = 8
      break
    case 13:
      radius = 8
      break
    case 14:
      radius = 8
      break
    case 15:
      radius = 12
      break
    case 16:
      radius = 15
      break
    case 17:
      radius = 20
      break
    default:
      radius = 30
  }
  return radius + additionalRadius
}

export function getControlIconProps(control, currentZoom, hasBeenFound) {
  let iconClasses = ''
  // use icons from this search https://fontawesome.com/icons?d=gallery&c=animals&m=free
  // dog
  // dove
  // frog
  // cat
  // horse
  // crow
  //
  // kiwi-bird
  // fish
  // bug
  // paw
  // hippo
  // otter
  // spider
  // dragon
  let iconName = ''
  let iconColorClass = ''
  const iconPack = 'fas'
  let iconSizeClass = ''

  switch (control.points) {
    case 1:
      iconClasses = 'fas fa-dog color-icons-1'
      iconName = 'dog'
      iconColorClass = 'color-icons-1'
      break
    case 2:
      iconClasses = 'fas fa-dove color-icons-2'
      iconName = 'dove'
      iconColorClass = 'color-icons-2'
      break
    case 3:
      iconClasses = 'fas fa-frog color-icons-3'
      iconName = 'frog'
      iconColorClass = 'color-icons-3'
      break
    case 4:
      iconClasses = 'fas fa-cat color-icons-4'
      iconName = 'cat'
      iconColorClass = 'color-icons-4'
      break
    case 5:
      iconClasses = 'fas fa-horse color-icons-5'
      iconName = 'horse'
      iconColorClass = 'color-icons-5'
      break
    default:
      iconClasses = 'fas fa-dog color-icons-1'
      iconName = 'dog'
      iconColorClass = 'color-icons-1'
  }
  // fa-university
  // fa-bomb
  // fa-leaf
  // fa-paw
  // fa-star
  // fa-star-half-full
  // fa-star-o

  switch (currentZoom) {
    case 15:
      iconSizeClass = 'fa-2x'
      break
    case 16:
      iconSizeClass = 'fa-3x'
      break
    case 17:
      iconSizeClass = 'fa-4x'
      break
    case 18:
      iconSizeClass = 'fa-5x'
      break
  }
  iconClasses += ' ' + iconSizeClass

  if (hasBeenFound) {
    iconClasses += ' control-found'
  }
  return {
    iconPack,
    iconClasses,
    iconSizeClass,
    iconName,
    iconColorClass
  }
}

export function isEntityVisible(
  entityGeoHash,
  theCurrentZoom,
  theCurrentBounds
) {
  const geoHashesInCurrentBounds = getGeoHashesInCurrentBounds(
    theCurrentZoom,
    theCurrentBounds
  )

  return !!geoHashesInCurrentBounds.find((geoHash) =>
    entityGeoHash.startsWith(geoHash)
  )
}

export class GeoSearchTooBigError extends Error {
  constructor(...args) {
    super(...args)
    this.name = this.constructor.name
  }
}

export class UnexpectedInputError extends Error {
  constructor(...args) {
    super(...args)
    this.name = this.constructor.name
  }
}

export function determineNewMapSearch(
  theCurrentZoom,
  theCurrentBounds,
  allPreviouslySearchedGeoHashes
) {
  if (!canSearch(theCurrentZoom)) {
    throw new GeoSearchTooBigError()
  }

  const geoHashesInCurrentBounds = getGeoHashesInCurrentBounds(
    theCurrentZoom,
    theCurrentBounds
  )

  const newGeoHashesInCurrentBounds = geoHashesInCurrentBounds.filter(
    (geoHashInCurrentBounds) => {
      return !hasSearchedThisAreaBefore(
        allPreviouslySearchedGeoHashes,
        geoHashInCurrentBounds
      )
    }
  )
  return new Set(newGeoHashesInCurrentBounds)
}

function hasSearchedThisAreaBefore(
  alreadySearchedGeoHashesArray,
  geoHashThisArea
) {
  return alreadySearchedGeoHashesArray.find((existingGeoHash) =>
    geoHashThisArea.startsWith(existingGeoHash)
  )
}

/**
 * The geohash abc contains the geohash abcd, thus the set (abcde, abcd, abcdf)
 * can be compressed to (abcd)
 * @param geoHashesSet
 * @returns {Set<any>}
 */
export function compressSetOfGeoHashes(geoHashesSet) {
  const geoHashesArray = [...geoHashesSet]
  // sort ascending, thus we start with the geohashes covering the largest area first
  geoHashesArray.sort(function(a, b) {
    return a.length - b.length
  })

  const newGeoHashesSet = new Set()
  geoHashesArray.forEach((geoHash) => {
    const newGeoHashesArray = [...newGeoHashesSet]
    if (!hasSearchedThisAreaBefore(newGeoHashesArray, geoHash)) {
      newGeoHashesSet.add(geoHash)
    }
  })
  return newGeoHashesSet
}

export function latLngToGeoHash(type, lat, lng) {
  switch (type) {
    case 'gameDefinition':
      return geohash.encode(lat, lng, GEO_HASH_PRECISION_GAME_DEF)
    default:
      throw new Error('Unknown type, cannot encode to geo hash: ' + type)
  }
}
