import { API, graphqlOperation } from 'aws-amplify'

import * as gqlMutations from '../../src/graphql/mutations'
import * as queries from '../../src/graphql/queries'
import * as queriesCustom from '../../src/graphql/queries-custom'

export const state = () => ({
  gameLevels: [],
  selectedGameLevel: null,
  /**
   * props:
   * - id
   * - controlId
   * - gameLevelId
   * - color
   * - opacity
   * - latLng: [lat, lng],
   */
  levelControls: [],
  // all level controls for all levels on a game
  levelControlsForGame: []
})

export const mutations = {
  resetStateOnGameCompletion(state) {
    state.gameLevels = []
    state.selectedGameLevel = null
    state.levelControls = []
    state.levelControlsForGame = []
  },
  setGameLevels(state, gameLevels) {
    state.gameLevels = gameLevels
  },
  setSelectedGameLevel(state, gameLevel) {
    state.selectedGameLevel = gameLevel
  },
  addGameLevel(state, newGameLevel) {
    state.gameLevels.push(newGameLevel)
  },
  deleteGameLevel(state, gameLevelId) {
    if (state.selectedGameLevel && state.selectedGameLevel.id === gameLevelId) {
      state.selectedGameLevel = null
    }
    state.gameLevels = state.gameLevels.filter(
      (gameLevel) => gameLevel.id !== gameLevelId
    )
  },
  deleteGameLevelControl(state, gameLevelControlId) {
    state.levelControls = state.levelControls.filter(
      (levelControl) => levelControl.id !== gameLevelControlId
    )
    state.levelControlsForGame = state.levelControlsForGame.filter(
      (levelControl) => levelControl.id !== gameLevelControlId
    )
  },

  updateGameLevel(state, updatedGameLevel) {
    state.gameLevels = state.gameLevels.map((gameLevel) => {
      if (gameLevel.id === updatedGameLevel.id) {
        return updatedGameLevel
      } else {
        return gameLevel
      }
    })
  },
  addControlToGameLevel(state, gameLevelControl) {
    state.levelControls.push(gameLevelControl)
  },
  removeControlFromGameLevel(state, id) {
    state.levelControls = state.levelControls.filter((lc) => lc.id !== id)
  },
  setGameLevelControls(state, gameLevelControls) {
    state.levelControls = gameLevelControls
  },
  setGameLevelControlsForGame(state, gameLevelControls) {
    state.levelControlsForGame = gameLevelControls
  },
  updatePointsForControl(state, updatedPoints) {
    state.levelControls = state.levelControls.map((levelControl) => {
      if (levelControl.id === updatedPoints.id) {
        return Object.assign({}, levelControl, { points: updatedPoints.points })
      } else {
        return levelControl
      }
    })
  }
}

export const actions = {
  async deleteGameLevel({ commit }, gameLevelId) {
    await API.graphql(
      graphqlOperation(gqlMutations.deleteGameLevel, {
        input: { id: gameLevelId }
      })
    )
    return commit('deleteGameLevel', gameLevelId)
  },
  async deleteGameLevelControls({ commit }, gameLevelControlId) {
    await API.graphql(
      graphqlOperation(gqlMutations.deleteGameLevelControl, {
        input: { id: gameLevelControlId }
      })
    )
    return commit('deleteGameLevelControl', gameLevelControlId)
  },

  async getGameLevels({ commit }, gameDefinition) {
    const options = {
      limit: 100,
      gameDefinitionId: gameDefinition.id
    }

    const result = await API.graphql(
      graphqlOperation(queries.gameLevelsByGameDefinitionIdByOrder, options)
    )
    const gameLevels = result.data.gameLevelsByGameDefinitionIdByOrder.items
    gameLevels.sort((l1, l2) => l1 - l2)
    return commit('setGameLevels', gameLevels)
  },
  async getGameLevel({ commit }, gameLevelId) {
    const options = {
      id: gameLevelId
    }
    const result = await API.graphql(
      graphqlOperation(queries.getGameLevel, options)
    )
    return commit('setSelectedGameLevel', result.data.getGameLevel)
  },
  async addGameLevel({ commit }, newGameLevel) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createGameLevel, {
        input: newGameLevel
      })
    )
    const newGameLevel3 = converters.gameLevel.fromServerToClient(
      result.data.createGameLevel
    )
    commit('setSelectedGameLevel', newGameLevel3)
    return commit('addGameLevel', newGameLevel3)
  },
  async updateGameLevel({ commit }, updatedGameLevel) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.updateGameLevel, {
        input: converters.gameLevel.fromClientToServer(updatedGameLevel)
      })
    )
    const updatedGameLevel2 = converters.gameLevel.fromServerToClient(
      result.data.updateGameLevel
    )
    return commit('updateGameLevel', updatedGameLevel2)
  },
  async removeControlFromGameLevel({ commit }, id) {
    await API.graphql(
      graphqlOperation(gqlMutations.deleteGameLevelControl, {
        input: {
          id
        }
      })
    )

    return commit('removeControlFromGameLevel', id)
  },
  async addControlToGameLevel({ commit }, newGameLevelControl) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createGameLevelControl, {
        input: newGameLevelControl
      })
    )

    const addedControl = converters.gameLevelControls.fromServerToClient(
      result.data.createGameLevelControl
    )

    return commit('addControlToGameLevel', addedControl)
  },
  // TODO
  async updatePoints({ commit }, updatedPoints) {
    // id: ID!
    //   owner: String
    // gameLevelControlGameLevelId: ID
    // gameLevelControlControlId: ID
    await API.graphql(
      graphqlOperation(gqlMutations.updateGameLevelControl, {
        input: updatedPoints
      })
    )

    return commit('updatePointsForControl', updatedPoints)
  },
  async getGameLevelControls({ commit }, { canEditGame, selectedGameLevel }) {
    if (!selectedGameLevel) {
      return commit('setGameLevelControls', [])
    }
    const options = { limit: 100, gameLevelId: selectedGameLevel.id }
    const query = canEditGame
      ? queries.gameLevelControlByGameLevelId
      : queriesCustom.gameLevelControlByGameLevelIdWithoutSecret

    const gameLevelControls = await API.graphql(
      graphqlOperation(query, options)
    )
    const gameLevelControls2 = gameLevelControls.data.gameLevelControlByGameLevelId.items
      .map(converters.gameLevelControls.fromServerToClient)
      .filter((glc) => glc.gameLevelId === selectedGameLevel.id)
    return commit('setGameLevelControls', gameLevelControls2)
  },
  async getGameLevelControlsForGame({ commit }, gameDefinitionId) {
    if (!gameDefinitionId) {
      return commit('setGameLevelControls', [])
    }
    const options = { limit: 100, gameDefinitionId }
    const gameLevelControls = await API.graphql(
      graphqlOperation(
        queriesCustom.listGameLevelControlByGameDefinitionIdWithoutSecret,
        options
      )
    )
    const gameLevelControls2 =
      gameLevelControls.data.gameLevelControlByGameDefinitionId.items
    return commit('setGameLevelControlsForGame', gameLevelControls2)
  }
}

const converters = {
  gameLevel: {
    fromClientToServer: (gameLevel) => {
      const newGameLevel = Object.assign({}, gameLevel, {
        gameLevelGameDefinitionId: gameLevel.gameDefinition.id
      })
      delete newGameLevel.gameDefinition
      delete newGameLevel.numberControls
      delete newGameLevel.createdAt
      delete newGameLevel.updatedAt
      return newGameLevel
    },
    fromServerToClient: (input) => input
  },
  gameLevelControls: {
    fromServerToClient: (gameLevelControl) => {
      return Object.assign({}, gameLevelControl.control, {
        id: gameLevelControl.id,
        idd: gameLevelControl.control.id + 100,
        color: '#9F0AA4',
        opacity: 1.0,
        latLng: [gameLevelControl.control.lat, gameLevelControl.control.lng],
        gameLevelId: gameLevelControl.gameLevel.id,
        controlId: gameLevelControl.control.id,
        points: gameLevelControl.points
      })
    },
    fromClientToServer: (input) => input
  }
}
