import { API, graphqlOperation } from 'aws-amplify'
import * as gqlMutations from '../../src/graphql/mutations'
import * as queries from '../../src/graphql/queries'
import { compressSetOfGeoHashes } from '~/services/MapCalculations.js'

export const state = () => ({
  allMaps: [],
  selectedMapIds: [],
  allMapsFromGeoHashes: [],
  selectedGeoHash: null
})

export const mutations = {
  setSelectedGeoHash(state, geoHash) {
    state.selectedGeoHash = geoHash
  },
  setAllMaps(state, maps) {
    state.allMaps = maps
  },
  appendMaps(state, newMaps) {
    const ids = state.allMaps.map((m) => m.id)
    newMaps.forEach((newMap) => {
      if (!ids.includes(newMap.id)) {
        state.allMaps.push(newMap)
        ids.push(newMap.id)
      }
    })
  },
  appendAllMapsFromGeoHashes(state, newGeoHashesArray) {
    const allMapsFromGeoHashesSet = new Set(state.allMapsFromGeoHashes)
    newGeoHashesArray.forEach((newGeoHash) =>
      allMapsFromGeoHashesSet.add(newGeoHash)
    )
    const compressedGeoHashes = compressSetOfGeoHashes(allMapsFromGeoHashesSet)
    state.allMapsFromGeoHashes = [...compressedGeoHashes]
  },
  setSelectedMapIds(state, mapIds) {
    state.selectedMapIds = mapIds
  },
  addMap(state, newMap) {
    state.allMaps.push(newMap)
  },
  deleteMap(state, mapId) {
    state.allMaps = state.allMaps.filter((aMap) => aMap.id !== mapId)
  },
  updateMap(state, updatedMap) {
    state.allMaps = state.allMaps.map((map) => {
      if (map.id === updatedMap.id) {
        return updatedMap
      } else {
        return map
      }
    })
  }
}

const converters = {
  maps: {
    fromServerToClient: (map) => {
      // we don't need the game definitions
      delete map.gameDefinitions
      return map
    }
  }
}

export const actions = {
  async getAllMaps({ commit }) {
    const options = {
      limit: 100
    }
    const allMaps = await API.graphql(
      graphqlOperation(queries.listOmaps, options)
    )
    const allMaps2 = allMaps.data.listOmaps.items.map(
      converters.maps.fromServerToClient
    )
    // console.info('ignoring these maps for now: ', allMaps2)

    return commit('setAllMaps', allMaps2)
  },
  async searchMaps({ commit }, searchParams) {
    const options = {
      limit: 100,
      state: searchParams.state,
      geoHash: { beginsWith: searchParams.geoHash }
    }
    const matchingMaps = await API.graphql(
      graphqlOperation(queries.omapByStateByGeoHash, options)
    )
    const matchingMaps2 = matchingMaps.data.omapByStateByGeoHash.items.map(
      converters.maps.fromServerToClient
    )
    console.info(
      `found ${matchingMaps.data.omapByStateByGeoHash.items.length} maps in area ${searchParams.geoHash} - ${searchParams.state}`
    )
    return commit('appendMaps', matchingMaps2)
  },

  async addMap({ commit }, newMap) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createOmap, { input: newMap })
    )

    const newMap2 = converters.maps.fromServerToClient(result.data.createOmap)
    return commit('addMap', newMap2)
  },
  async updateMap({ commit }, updatedMap) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.updateOmap, { input: updatedMap })
    )
    const updatedMap2 = converters.maps.fromServerToClient(
      result.data.updateOmap
    )
    return commit('updateMap', updatedMap2)
  },
  async deleteMap({ commit }, mapId) {
    await API.graphql(
      graphqlOperation(gqlMutations.deleteOmap, { input: { id: mapId } })
    )
    return commit('deleteMap', mapId)
  }
}
