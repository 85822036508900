<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item :to="{ path: '/' }" tag="router-link">
          <strong>Uteruter</strong>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item
          :to="item.to"
          v-for="(item, key) of visibleMenuItems"
          :key="key"
          tag="nuxt-link"
          exact-active-class="is-active"
        >
          <b-icon :icon="item.icon" pack="fas" />&nbsp; {{ item.title }}
        </b-navbar-item>
      </template>

      <template slot="end">
        <b-navbar-item
          :to="userMenuItem.to"
          tag="nuxt-link"
          exact-active-class="is-active"
        >
          <b-icon :icon="userMenuItem.icon" pack="fas" />&nbsp;
          {{ userMenuItem.title }}
          <strong v-if="isEditorOrAdmin">Admin mode</strong>
        </b-navbar-item>
      </template>
    </b-navbar>

    <section class="main-content columns">
      <div class="container column is-12">
        <nuxt />
      </div>
    </section>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue'
export default {
  data() {
    return {
      items: [
        {
          title: this.$t('general.home'),
          icon: 'home',
          to: { name: 'index' },
          requireLogin: false
        },
        {
          title: this.$t('game.title'),
          icon: 'running',
          to: { name: 'game-definitions' },
          requireLogin: true
        },
        {
          title: this.$t('general.map'),
          icon: 'map',
          to: { name: 'maps' },
          requireLogin: true
        },
        {
          title: this.$t('general.punches'),
          icon: 'clipboard-list',
          to: { name: 'punches' },
          requireLogin: true
        },
        {
          title: this.$t('general.score'),
          icon: 'star',
          to: { name: 'score' },
          requireLogin: true
        }
      ]
    }
  },
  computed: {
    userMenuItem() {
      if (this.isLoggedIn) {
        return {
          title: this.$store.state.user.currentUser.name,
          icon: 'user',
          to: { name: 'user' }
        }
      } else {
        return {
          title: this.$t('user.sign-in'),
          icon: 'account',
          to: { name: 'sign-in' }
        }
      }
    },
    visibleMenuItems() {
      return this.items.filter((item) => !item.requireLogin || this.isLoggedIn)
    },
    isLoggedIn() {
      return this.$store.state.user.currentUser.isLoggedIn
    },
    isEditorOrAdmin() {
      return this.$store.state.user.currentUser.isEditorOrAdmin
    }
  },
  mounted() {
    this.setupAuthListeners()
  },

  methods: {
    setupAuthListeners() {
      const that = this

      async function setCurrentUser(info) {
        if (info === 'signedOut') {
          that.$store.commit('user/setCurrentUser', null)
          return
        }

        try {
          const currentUser = await that.$Amplify.Auth.currentAuthenticatedUser()
          if (currentUser) {
            that.$store.commit('user/setCurrentUser', currentUser)
          }
        } catch (err) {
          if (err === 'not authenticated') {
            that.$store.commit('user/setCurrentUser', null)
          } else {
            this.$buefy.toast.open({
              message: this.$t('general.error-unexpected'),
              duration: 5000,
              type: 'is-danger'
            })
          }
        }
      }
      AmplifyEventBus.$on('authState', (info) => {
        setCurrentUser(info)
      })
      setCurrentUser('-')
    }
  }
}
</script>
