import { API, graphqlOperation } from 'aws-amplify'
import * as gqlMutations from '../../src/graphql/mutations'
import * as queries from '../../src/graphql/queries'
import { CannotDeleteDueToConflicts } from '~/services/UtilityFunctions.js'

const uuidv4 = require('uuid/v4')

export const state = () => ({
  controls: [
    // {
    //   id: 1,
    //   idd: 101, // added idd, in order to avoid getting warnings about duplicates in map-vue
    //   visualCode: 'AB123',
    //   latLng: [58.483903, 8.732966],
    //   color: '#9F0AA4',
    //   opacity: 1.0
    // }
  ],
  omapStates: [
    {
      id: 'PRIVATE'
    },
    {
      id: 'PUBLIC'
    }
  ],
  token:
    'pk.eyJ1Ijoic3RpZ2FsdmlzIiwiYSI6ImNrMGEzcmpsZjBmZWMzbXJsaHN4d21wNXUifQ.twkc5X1My-d-KYjczZkOIw',
  geoApifyToken: '8031704d597642c2949a0c3a58a52209',
  osmLayer: {
    name: 'Open Street Map',
    attribution:
      "Powered by <a href='https://www.geoapify.com/' target='_blank'>Geoapify</a> | &copy; OpenStreetMap <a href='https://www.openstreetmap.org/copyright' target='_blank'>contributors</a>",
    sourceType: 'TILES',
    mapLocationUrl:
      'https://maps.geoapify.com/v1/tile/carto/{z}/{x}/{y}.png?api_key=' +
      '8031704d597642c2949a0c3a58a52209',
    visible: false
  },
  kartverketLayer: {
    name: 'Kartverket',
    attribution: 'Kartverket',
    sourceType: 'TILES',
    mapLocationUrl:
      'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}',
    visible: false
  },
  currentCenter: [58.483903, 8.734966],
  currentPosition: null,
  currentZoom: null,
  userLocation: null,
  useSmallMap: false,
  showIconsForControls: false,
  selectedControl: null
})

export const mutations = {
  resetStateOnGameCompletion(state) {
    state.controls = []
  },
  setCurrentZoom(state, zoom) {
    state.currentZoom = zoom
  },
  setShowIconsForControls(state, showIconsForControls) {
    state.showIconsForControls = showIconsForControls
  },
  setPosition(state, location) {
    state.currentPosition = [
      location.coords.latitude,
      location.coords.longitude
    ]
  },
  setUserLocation(state, userLocation) {
    state.userLocation = userLocation
  },
  setCurrentCenter(state, latLng) {
    state.currentCenter = latLng
  },
  setUseSmallMap(state, useSmallMap) {
    state.useSmallMap = useSmallMap
  },
  setControls(state, controls) {
    state.controls = controls
  },
  selectControl(state, control) {
    state.selectedControl = converters.controls.fromServerToClient(control)
  },
  clearSelectedControl(state) {
    state.selectedControl = null
  },
  addControl(state, newControl) {
    state.controls.push(newControl)
  },
  updateControl(state, updatedControl) {
    state.controls = state.controls.map((control) => {
      if (control.id === updatedControl.id) {
        return Object.assign({}, updatedControl, {
          secretCode: control.secretCode
        })
      } else {
        return control
      }
    })
  },
  deleteControl(state, controlId) {
    state.controls = state.controls.filter(
      (control) => control.id !== controlId
    )
  }
}

const converters = {
  controls: {
    fromServerToClient: (control) => {
      control.idd = control.id + 100
      control.color = '#9F0AA4'
      control.weight = 6
      control.opacity = 1.0
      control.latLng = [control.lat, control.lng]
      return control
    }
  }
}

export const actions = {
  async deleteControl({ commit }, { controlId, forceDelete }) {
    const result = await API.graphql(
      graphqlOperation(queries.gameLevelControlByControlId, {
        controlId,
        limit: 10
      })
    )
    const gameLevelControlsUsingTheControl =
      result.data.gameLevelControlByControlId.items
    if (gameLevelControlsUsingTheControl.length > 0) {
      if (forceDelete) {
        console.info(
          'Force deleting: ' + gameLevelControlsUsingTheControl.length
        )
        const gameLevelControlDeletePromises = []
        gameLevelControlsUsingTheControl.forEach((gameLevelControl) => {
          const deleteGameLevelControlPromise = this.dispatch(
            'gameLevels/removeControlFromGameLevel',
            gameLevelControl.id
          )
          gameLevelControlDeletePromises.push(deleteGameLevelControlPromise)
        })
        await Promise.all(gameLevelControlDeletePromises)
      } else {
        throw new CannotDeleteDueToConflicts(gameLevelControlsUsingTheControl)
      }
    }

    await API.graphql(
      graphqlOperation(gqlMutations.deleteControl, {
        input: { id: controlId }
      })
    )
    return commit('deleteControl', controlId)
  },
  /**
   * Get controls for a given user. Normally the user which owns the game which is being edited.
   * Admins can get controls for other users, while Editors can only get their own controls.
   */
  async getControlsForUser({ commit }, config) {
    const options = {
      limit: 100,
      owner: config.user
    }
    const query = queries.controlsByOwner
    const allControls = await API.graphql(graphqlOperation(query, options))
    const allControls2 = allControls.data.controlsByOwner.items.map(
      converters.controls.fromServerToClient
    )
    return commit('setControls', allControls2)
  },
  async addControl({ commit }, newControl) {
    const newControlWithId = Object.assign({}, newControl, { id: uuidv4() })
    const result = await API.graphql(
      graphqlOperation(gqlMutations.createControl, { input: newControlWithId })
    )
    const newControl2 = converters.controls.fromServerToClient(
      result.data.createControl
    )
    return commit(
      'addControl',
      Object.assign({}, newControl2, { secretCode: newControl.secretCode })
    )
  },
  async updateControl({ commit }, updatedControl) {
    const result = await API.graphql(
      graphqlOperation(gqlMutations.updateControl, { input: updatedControl })
    )
    const updatedControl2 = converters.controls.fromServerToClient(
      result.data.updateControl
    )
    return commit('updateControl', updatedControl2)
  }
}
