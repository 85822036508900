// amplify config start
import Vue from 'vue'
import Amplify, * as AmplifyModules from 'aws-amplify'
// import Amplify from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
// import API from "@aws-amplify/api";
// import PubSub from "@aws-amplify/pubsub";
import awsconfig from '../aws-exports'
Amplify.configure(awsconfig)
// API.configure(awsconfig);
// PubSub.configure(awsconfig);

Vue.use(AmplifyPlugin, AmplifyModules)
// amplify config end
