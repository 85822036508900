import { API, graphqlOperation } from 'aws-amplify'
import * as queriesCustom from '../../src/graphql/queries-custom'

export const state = () => ({
  punches: [],
  punchesForCurrentGame: []
})

export const mutations = {
  setPunches(state, punches) {
    state.punches = punches
  },
  setPunchesForCurrentGame(state, punches) {
    state.punchesForCurrentGame = punches
  }
}

export const actions = {
  async getPunches({ commit }, { queryParams }) {
    const options = Object.assign({ limit: 100 }, queryParams)
    console.log('options ', options)
    const query = queriesCustom.controlVisitsByOwnerWithoutSecret
    const punches = await API.graphql(graphqlOperation(query, options))

    return commit(
      'setPunches',
      punches.data.controlVisitsByOwnerByControlId.items
    )
  },
  async getPunchesForThisOwnerAndGame({ commit }, queryParams) {
    const options = Object.assign(
      { limit: 100 },
      {
        owner: queryParams.owner,
        gameStateGameId: {
          eq: queryParams.gameStateGameId
        }
      }
    )
    const query =
      queriesCustom.controlVisitsByOwnerByGameStateGameIdWithoutSecret
    const punches = await API.graphql(graphqlOperation(query, options))

    return commit(
      'setPunchesForCurrentGame',
      punches.data.controlVisitsByOwnerByGameStateGameId.items
    )
  },
  /**
   * Currently unused
   * @param commit
   * @param queryParams
   * @returns {Promise<*>}
   */
  async getPunchesByOwnerByGameStateId({ commit }, queryParams) {
    const options = Object.assign(
      { limit: 100 },
      {
        owner: queryParams.owner,
        gameStateId: {
          eq: queryParams.gameStateId
        }
      }
    )
    const query = queriesCustom.controlVisitsByOwnerByGameStateIdWithoutSecret
    const punches = await API.graphql(graphqlOperation(query, options))

    return commit(
      'setPunchesForCurrentGame',
      punches.data.controlVisitsByOwnerByGameStateId.items
    )
  }
}
