/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c5145f20-80f4-4e11-b2e8-c7492d1cc436",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ZskFoBfad",
    "aws_user_pools_web_client_id": "56ntcvvq574qkipvp5ur9bv1eq",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://tonhgweemzai5gbk2rsr7pepcy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
