import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../graphql/queries'

export const state = () => ({
  gameScores: [],
  gameScoresByOwner: []
})

export const mutations = {
  setGameScores(state, gameScores) {
    state.gameScores = gameScores
  },
  appendToGameScores(state, gameScores) {
    gameScores.forEach((gameScore) => state.gameScores.push(gameScore))
  },
  setGameScoresByOwner(state, gameScores) {
    state.gameScoresByOwner = gameScores
  },
  setGameScoresNextToken(state, { query, nextToken }) {
    state.gameScoresNextToken = { query, nextToken }
  }
}

export const actions = {
  async getGameScores({ commit }, filter) {
    const variables = {
      period: filter.period,
      state: 'PUBLIC',
      scoreType: 'POINT_COUNT',
      gameGeoHash: filter.gameGeoHash,
      leagueId: 'NONE',
      ownerType: filter.ownerType,
      sortDirection: 'DESC',
      limit: filter.perPage,
      nextToken: filter.nextToken ? filter.nextToken : ''
    }

    const gameScores = await API.graphql(
      graphqlOperation(queries.listGameScoreRanking, variables)
    )
    const gameScores2 = gameScores.data.listGameScoreRanking.items
    const gameScoresNexToken = gameScores.data.listGameScoreRanking.nextToken
    console.log('next token: ', gameScoresNexToken)
    commit('setGameScoresNextToken', {
      query: variables,
      nextToken: gameScoresNexToken
    })
    return commit('setGameScores', gameScores2)
  },
  async getGameScoresNextPage({ commit, state }) {
    const queryWithNextToken = Object.assign(
      {},
      state.gameScoresNextToken.query,
      { nextToken: state.gameScoresNextToken.nextToken }
    )

    const gameScores = await API.graphql(
      graphqlOperation(queries.listGameScoreRanking, queryWithNextToken)
    )
    const gameScores2 = gameScores.data.listGameScoreRanking.items
    const gameScoresNexToken = gameScores.data.listGameScoreRanking.nextToken
    console.log('next token: ', gameScoresNexToken)
    commit('setGameScoresNextToken', {
      query: queryWithNextToken,
      nextToken: gameScoresNexToken
    })
    return commit('appendToGameScores', gameScores2)
  },
  async getGameScoresByOwner({ commit }, filter) {
    let state = filter.scoreState ? filter.scoreState : 'PUBLIC'
    if (filter.ownerType === 'TEAM') {
      state = 'PUBLIC' // all team scores are public
    }

    const variables = {
      period: filter.period,
      state,
      scoreType: 'POINT_COUNT',
      gameGeoHash: filter.gameGeoHash,
      leagueId: 'NONE',
      ownerType: filter.ownerType,
      sortDirection: 'DESC',
      limit: 10, // TODO how many are necessary here? Should be one?
      nextToken: ''
    }

    const gameScores = await API.graphql(
      graphqlOperation(queries.listGameScoreByOwner, variables)
    )
    const gameScores2 = gameScores.data.listGameScoreByOwner.items
    return commit('setGameScoresByOwner', gameScores2)
  }
}
