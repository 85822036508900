import { Auth, API, graphqlOperation } from 'aws-amplify'
import * as gqlMutations from '../graphql/mutations'

const defaultState = {
  email: null,
  email_verified: false,
  phone_number: null,
  phone_number_verified: false,
  sub: null, // the user id
  name: null, // the user name
  userGroups: [],
  isAdmin: false,
  isEditor: false,
  isEditorOrAdmin: false,
  isLoggedIn: false // TODO replace this with userState
}

export const state = () => ({
  currentUser: Object.assign({}, defaultState),
  signInHelp: {
    userEmail: null,
    showForgottenPasswordMsg: false
  },
  preferences: {
    tableRowsAsCards: false
  }
})

function getPublishResultsSetting(currentUser) {
  // eslint-disable-next-line standard/computed-property-even-spacing
  const publishResultsRawValue = currentUser['custom:publish_results']
  if (publishResultsRawValue === undefined || publishResultsRawValue === null) {
    return null
  }

  return publishResultsRawValue === true ? 'PUBLIC' : 'PRIVATE'
}

export const mutations = {
  clearSignInHelp(state) {
    state.signInHelp = {
      userEmail: null,
      showForgottenPasswordMsg: false
    }
  },
  setTableRowsAsCards(state, tableRowsAsCards) {
    state.preferences.tableRowsAsCards = tableRowsAsCards
  },
  setShowForgottenPasswordMsg(state, show) {
    state.signInHelp.showForgottenPasswordMsg = show
  },
  setUserEmailInSignInHelp(state, email) {
    state.signInHelp.userEmail = email
  },
  setCurrentUser(state, user) {
    if (user) {
      const userGroups =
        user.signInUserSession.accessToken.payload['cognito:groups']
      const currentUser = Object.assign({}, user.attributes, {
        userGroups: userGroups || []
      })
      currentUser.isAdmin = currentUser.userGroups.includes('Admin')
      currentUser.isEditor = currentUser.userGroups.includes('Editor')
      currentUser.isEditorOrAdmin = currentUser.isEditor || currentUser.isAdmin
      currentUser.isLoggedIn = !!currentUser.sub
      currentUser.publishResults = getPublishResultsSetting(currentUser)

      state.currentUser = currentUser

      /**
       * Only log the user id, in case it is necessary to delete the user - it will not be possible to
       * trace back which user originally did the request.
       */
      this.$bugsnag.user = {
        id: state.currentUser.sub
      }
    } else {
      state.currentUser = Object.assign({}, defaultState)
      this.$bugsnag.user = {}
    }
  }
}

export const actions = {
  async changePublishResults({ commit }, publishResults) {
    const user = await Auth.currentAuthenticatedUser()

    const options = {
      owner: user.attributes.sub
    }

    await API.graphql(graphqlOperation(gqlMutations.deleteGameScores, options))

    const attributes = {
      'custom:publish_results': publishResults ? 'true' : 'false'
    }
    await Auth.updateUserAttributes(user, attributes)

    const user2 = await Auth.currentAuthenticatedUser({ bypassCache: true })
    return commit('setCurrentUser', user2)
  },
  async signOut({ commit }) {
    const data = await Auth.signOut()
    console.log(data)
    return commit('setCurrentUser', null)
  },
  async forgotPassword({ commit }, email) {
    const data = await Auth.forgotPassword(email)
    console.log(data)
  },
  async forgotPasswordConfirm({ commit }, forgotPasswordConfirmArgs) {
    const data = await Auth.forgotPasswordSubmit(
      forgotPasswordConfirmArgs.username,
      forgotPasswordConfirmArgs.confirmationCode,
      forgotPasswordConfirmArgs.newPassword
    )

    console.log(data)
  },
  async signUp({ commit }, signUpArgs) {
    const username = signUpArgs.email
    const email = signUpArgs.email
    const name = signUpArgs.name
    const password = signUpArgs.password
    // const phoneNumber = signUpArgs.phoneNumber
    const data = await Auth.signUp({
      username,
      password,
      attributes: {
        email, // optional
        // phone_number: phoneNumber, // optional - E.164 number convention
        name
        // other custom attributes
      },
      validationData: [] // optional
    })

    console.log(data)
  },
  async signUpConfirm({ commit }, signUpArgs) {
    const username = signUpArgs.username
    const code = signUpArgs.confirmationCode
    // After retrieving the confirmation code from the user
    const data2 = await Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
    console.log('sign up confirmed', data2)
  },
  async signUpResendCode({ commit }, signUpArgs) {
    const username = signUpArgs.username
    const data3 = await Auth.resendSignUp(username)
    console.log('code resent successfully', data3)
  },

  async signIn({ commit }, signInArgs) {
    const username = signInArgs.username
    const password = signInArgs.password
    if (!(username && password)) {
      throw new Error(`Missing username ${username} password: ${password}`)
    }
    const user = await Auth.signIn(username, password)
    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA'
    ) {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = 'TODO'
      const mfaType = 'TODO'
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        code, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      )
      console.log('user confirmed sign in: ', loggedUser)
    }
    // else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //   const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
    //   // You need to get the new password and required attributes from the UI inputs
    //   // and then trigger the following function with a button click
    //   // For example, the email and phone_number are required attributes
    //   const { username, email, phone_number } = getInfoFromUserInput()
    //   const loggedUser = await Auth.completeNewPassword(
    //     user, // the Cognito User Object
    //     newPassword, // the new password
    //     // OPTIONAL, the required attributes
    //     {
    //       email,
    //       phone_number
    //     }
    //   )
    // }
    // else if (user.challengeName === 'MFA_SETUP') {
    //   // This happens when the MFA method is TOTP
    //   // The user needs to setup the TOTP before using it
    //   // More info please check the Enabling MFA part
    //   Auth.setupTOTP(user)
    // }
    else {
      // The user directly signs in
      console.log('signed in OK')
      console.log(user)
      commit('clearSignInHelp')
      return commit('setCurrentUser', user)
    }
  }
}
