import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10888f5e = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _39d31f16 = () => interopDefault(import('../pages/forgotten-password.vue' /* webpackChunkName: "pages/forgotten-password" */))
const _67b6dbe8 = () => interopDefault(import('../pages/forgotten-password-confirm.vue' /* webpackChunkName: "pages/forgotten-password-confirm" */))
const _64fdf56f = () => interopDefault(import('../pages/game-definitions/index.vue' /* webpackChunkName: "pages/game-definitions/index" */))
const _7b0a05c8 = () => interopDefault(import('../pages/game-map.vue' /* webpackChunkName: "pages/game-map" */))
const _6cd6dd7d = () => interopDefault(import('../pages/game-state.vue' /* webpackChunkName: "pages/game-state" */))
const _0efd79ca = () => interopDefault(import('../pages/korona.vue' /* webpackChunkName: "pages/korona" */))
const _b0b03844 = () => interopDefault(import('../pages/maps.vue' /* webpackChunkName: "pages/maps" */))
const _f4ac57bc = () => interopDefault(import('../pages/plain-map.vue' /* webpackChunkName: "pages/plain-map" */))
const _57510e65 = () => interopDefault(import('../pages/punches.vue' /* webpackChunkName: "pages/punches" */))
const _62fccfdb = () => interopDefault(import('../pages/score.vue' /* webpackChunkName: "pages/score" */))
const _3e4dfa84 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _15214d98 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _45b695a7 = () => interopDefault(import('../pages/sign-up-confirm.vue' /* webpackChunkName: "pages/sign-up-confirm" */))
const _3762c826 = () => interopDefault(import('../pages/sign-up-resend-code.vue' /* webpackChunkName: "pages/sign-up-resend-code" */))
const _e7e3e1dc = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _2cce5c7d = () => interopDefault(import('../pages/game-definitions/new.vue' /* webpackChunkName: "pages/game-definitions/new" */))
const _d80752de = () => interopDefault(import('../pages/game-definitions/selected/index.vue' /* webpackChunkName: "pages/game-definitions/selected/index" */))
const _275a1fc3 = () => interopDefault(import('../pages/game-definitions/selected/levels/index.vue' /* webpackChunkName: "pages/game-definitions/selected/levels/index" */))
const _57190bd1 = () => interopDefault(import('../pages/game-definitions/selected/levels/new.vue' /* webpackChunkName: "pages/game-definitions/selected/levels/new" */))
const _246eea86 = () => interopDefault(import('../pages/game-definitions/selected/levels/selected/index.vue' /* webpackChunkName: "pages/game-definitions/selected/levels/selected/index" */))
const _a861fdca = () => interopDefault(import('../pages/game-definitions/selected/levels/selected/controls.vue' /* webpackChunkName: "pages/game-definitions/selected/levels/selected/controls" */))
const _9814cbca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _10888f5e,
    name: "error"
  }, {
    path: "/forgotten-password",
    component: _39d31f16,
    name: "forgotten-password"
  }, {
    path: "/forgotten-password-confirm",
    component: _67b6dbe8,
    name: "forgotten-password-confirm"
  }, {
    path: "/game-definitions",
    component: _64fdf56f,
    name: "game-definitions"
  }, {
    path: "/game-map",
    component: _7b0a05c8,
    name: "game-map"
  }, {
    path: "/game-state",
    component: _6cd6dd7d,
    name: "game-state"
  }, {
    path: "/korona",
    component: _0efd79ca,
    name: "korona"
  }, {
    path: "/maps",
    component: _b0b03844,
    name: "maps"
  }, {
    path: "/plain-map",
    component: _f4ac57bc,
    name: "plain-map"
  }, {
    path: "/punches",
    component: _57510e65,
    name: "punches"
  }, {
    path: "/score",
    component: _62fccfdb,
    name: "score"
  }, {
    path: "/sign-in",
    component: _3e4dfa84,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _15214d98,
    name: "sign-up"
  }, {
    path: "/sign-up-confirm",
    component: _45b695a7,
    name: "sign-up-confirm"
  }, {
    path: "/sign-up-resend-code",
    component: _3762c826,
    name: "sign-up-resend-code"
  }, {
    path: "/user",
    component: _e7e3e1dc,
    name: "user"
  }, {
    path: "/game-definitions/new",
    component: _2cce5c7d,
    name: "game-definitions-new"
  }, {
    path: "/game-definitions/selected",
    component: _d80752de,
    name: "game-definitions-selected"
  }, {
    path: "/game-definitions/selected/levels",
    component: _275a1fc3,
    name: "game-definitions-selected-levels"
  }, {
    path: "/game-definitions/selected/levels/new",
    component: _57190bd1,
    name: "game-definitions-selected-levels-new"
  }, {
    path: "/game-definitions/selected/levels/selected",
    component: _246eea86,
    name: "game-definitions-selected-levels-selected"
  }, {
    path: "/game-definitions/selected/levels/selected/controls",
    component: _a861fdca,
    name: "game-definitions-selected-levels-selected-controls"
  }, {
    path: "/",
    component: _9814cbca,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
